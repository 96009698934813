import React from "react";
import { Layout } from "@startuponsale/gatsby-shared-ui";
import { Link } from "gatsby";

const TOS = () => {
  return (
    <div>
      <Layout>
        <div className="w-full services bg-white">
          <div className="max-w-6xl mx-auto px-4 pt-8 pb-20 md:pb-64">
            <h1>
              Términos y condiciones de uso
            </h1>
            <p>
              Te damos la bienvenida a startuponsale.com, la comunidad donde comprar o vender tanto proyectos personales como startups.
            </p><br/>
            <h2>1. Información General</h2>
            <p>Los presentes Términis y condiciones de uso rigen el uso del sitio web <Link to="/" className="text-dpink">startuponsale.com</Link>, en adelante (SoS).
            Si desea ponerse en contacto con SoS para tratar temas relacionados con este sitio web, puede hacerlo mediante el formulario de contacto disponible en <Link to="/contactanos" className="text-dpink">Contáctanos</Link>.
            </p>
            <p>El acceso y el uso de este sitio web es responsabilidad del usuario, que está sujeto a la aceptación de los presentes términos y condiciones de uso,
              de la política de privacidad y el uso de datos aquí transcritos. El uso de este sitio web y las relaciones entre SoS y los usuarios 
              se rigen por la legislación española aplicable.
            </p>
            <p>SoS se reserva el derecho de cambiar los presentes términos y condiciones de uso o revisar la política de privacidad y el uso de datos siempre que lo considere oportuno,
              en cualquier momento, con o sin previo aviso. Puede consultar la versión actual desde <Link to="/informacion/terminos-y-condiciones" className="text-dpink">Términos y condiciones</Link> que se 
              encuentra en el pie de página de la web. La versión más actual sustituye todas las versiones anteriores.
            </p>
            <p>Si no está de acuerdo con estos Términos y condiciones, no utilice el sitio web.</p>

            <h2>2. Objeto</h2>
            <p>El objetivo de SoS es ofrecer una plataforma y una comunidad online que permite poner en contacto emprendedores o propietarios que quieren vender sus proyectos o empresas con inversores que quieren comprar esos proyectos o empresas.
              Así mismo, SoS tiene como objetivo proporcionar un directorio con información relevante de proyectos o empresas interesantes para la compra/venta.</p>
            
            <h2>3. Propiedad Intelectual</h2>
            <p>SoS es propietario de los derechos de propiedad intelectual, o ha obtenido todas las autorizaciones o licencias necesarias para su explotación, 
              nombre de dominio, marcas y logotipos, la aplicación y otras obras e invenciones relacionadas con el sitio web, así como su contenido.
            </p>
            <p>El contenido del sitio web, incluyendo diseños, aplicaciones, texto, imágenes y código fuente (en adelante, el "Contenido") están protegidos por la propiedad intelectual e industrial.</p>
            <p>SoS, como el responsable de la recopilación de los datos provesionales publicados en el sitio web y su comunidad online, es titular de los derechos de propiedad intelectual de la base de datos 
              correspondiente, que no puede ser reproducida en su totalidad o en parte, o transmitido ni registrado por ningún sistema de recuperación de información. </p>
            <p>Bajo ninguna circunstancia se puede utilizar el contenido del sitio web, reproducir, copiar o transmitir en cualquier forma sin el consentimiento previo y por 
              escrito y explícito de SoS.
            </p> 
            <p>Los usuarios autorizan y, si es necesario, ceden a SoS el uso de los contenidos incorporados en el sitio web para su transformación, explotación, publicación, distribución, comunicación pública, gratuita, universal y sin limitación geográfica o tiempo.</p>
            <p>Los usuarios afirman que poseen todos los derechos, licencias y permisos para publicar en el sitio web de los contenidos publicados y exonerar a SoS de toda responsabilidad.</p>

            <h2>4. Capacidad para aceptar los términos y condiciones de uso</h2>
            <p>El usuario confirma ser mayor de 18 años de edad o que cuenta con la autorización legal de sus padres o tutores y ser plenamente capaz de
               consentir y aceptar los términos, condiciones, obligaciones, afirmaciones, representaciones y garantías descritas en estos Términos y condiciones de uso,
               y respetar y cumplir los mismos. Si tiene menos de 18 años de edad y no posee una autoriación legal de los padres o representantes legales, no utilice el sitio web.
            </p>

            <h2>5. Contenidos y garantías</h2>
            <p>La consulta y el uso de la web por parte del usuario es gratuito y no requiere registro. No obstante, y con el fin de gestionar los servicios ofrecidos por el sitio web, 
              tales como, pro no limitado a, gestionar consultas o poner en contacto entre usuarios los usuarios deben completar un formulario de registro y aceptar estas condiciones de uso y política de privacidad.
            </p>
            <p>El registro por parte del usuario es gratis, sin prejuicio de la posibilidad de que en un futuro SoS pueda establecer un precio o tasa por el servicio, el cual será debidamente avisado previamente a los usuarios.</p>
            <p>El usuario declara que toda la información proporcionada es veraz. A tal efecto, el usuario garantiza la autenticidad de todos aquellos datos que comunique 
              como consecuencia de cumplimentar en la web o modificar o actualizar sus datos de contacto o del proyecto emprendedor. Del mismo modo, es responsabilidad del usuario mantener 
              todas la información facilitada permamentemente actualizada de forma que responda, en todo momento, a la situación real del proyecto. En cualquier caso, el usuario será el único responsable de cualquier
               información falsa o inexacta suministrada y el daño causado a SoS o terceros.
            </p>
            <p>El usuario se compromente a hacer un uso correcto del portal de conformidad con la normativa vigente y los presentes Términos y condiciones de uso, así como un uso justo y pacifico.</p>
            <br/>
            <p>El usuario se compromete a no publicar en los contenidos de la web cumpliendo con las siguientes normas de conducta:
              <ul className="list-disc list-outside p-4 ml-4">
                <li>No publicar contenidos que, en modo alguno, pueda menospreciar o violar los derechos fundamentales y las libertades públicas reconocidos en la Constitución, los tratados internacionales y otras leyes aplicables.</li>
                <li>Que induzcan, inciten o promuevan actuaciones delictivas, denigratorias, difamatorias, violentas o, en general, contrarios a la ley, la moral y las buenas costumbres generalmente aceptadas y el orden público.</li>
                <li>Contenidos que induzcan, induzcan, inciten o promuevan actuaciones, actitudes o juicios discriminatorios sobre sexo, raza, religión, creencias, edad o condición.</li>
                <li>Que sea injurioso, ofensivo y / o degradantes.</li>
                <li>Que sea contrario al derecho al honor, personal e intimidad o derechos de imagen.</li>
                <li>Que viole las normas establecidas en la confidencialidad de comunicaciones, protección de datos, propiedad intelectual u otras leyes aplicables.</li>
                <li>Que se refieran a la publicidad de sitios que son competidores o anunciantes de SoS.</li>
              </ul>
              </p>
              <p>SoS se reserva el derecho de eliminar de la página web, sin previo aviso, todo el contenido que viole estos términos legales. SoS de ninguna manera se hace responsable del contenido ni de las opiniones expresadas por el usuario.</p>

            <h2>6. Política de privacidad</h2>
            <p> Responsable: Kaibigan Investements, S.L. ("SoS"). 
              Finalidad: 
              (i) gestionar y tramitar la inscripción del solicitante como usuario de la plataforma y la relación con las personas de contacto y la gestión de su cuenta de usuario; 
              (ii) remitir información relativa a las actividades organizadas por SoS o aquellas en las que participe, así como información sobre oportunidades de compra/venta de proyectos o empresas, nuestras novedades, beneficios del sitio web y toda aquella información que consideremos de vuestro interés. 
              
              Legitimación: 
              Para la finalidad (i) el interés legítimo del interesado y SoS para gestionar su relación de contacto y relación comercial. 
              Para la finalidad (ii) el consentimiento otorgado por el usuario. 
              
              Destinatarios: Está prevista la cesión a empresas del Grupo Kaibigan. Transferencia de datos a terceros países u organizaciones: Disqus, Inc., Facebook, Inc., Google LLC., Mixpanel, Inc., The Rocket Science Group LLC d/b/a MailChimp, Amazon Web Services, Inc., Netlify, todos ellos encargados de tratamiento fuera de la UE, acogidos a “Privacy Shield”.
              
              Derechos: puede ejercitar los derechos de acceso, rectificación, supresión, oposición, portabilidad y limitación al tratamiento de sus datos personales mediante solicitud en el <Link to="/contactanos">formulario de contacto</Link>, SoS puede reclamar una copia de documento identificativo en vigor. 
              Asimismo, tiene derecho a recabar la tutela de la Agencia Española de Protección de datos a través de su página web <a href="http://www.agpd.es" className="text-dpink">www.agpd.es</a>. 
              
              Información adicional: Es obligatorio que sea mayor de edad y que nos facilite sus datos para autenticarlos y habilitar su cuenta de usuario en el sitio web gestionado por SoS. 
              Puede consultar nuestra política de protección de datos en la <Link to="/informacion/privacidad" className="text-dpink">Política de Privacidad y las Cookies</Link>.
            </p>

            <h2>7. Ley aplicable y jurisdicción</h2>
            <p>Los términos y el uso del sitio web se regirán e interpretarán de acuerdo con las Leyes de España, así como a cualquier controversia derivada
              de la utilización de este servicio, las partes se someten a la jurisdicción de los tribunales de la ciudad de Barcelona.
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default TOS;
